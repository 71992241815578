body {
  background-color: #fff;
  margin: 0;
  font-family: sans-serif;
}

a {
  color: inherit;
  margin: inherit;
  text-decoration: inherit;
}

@font-face {
    font-family: "Maledpan";
    src: url(fonts/maledpan.woff2) format("woff2"), url(./fonts/maledpan.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Maledpan";
    src: url(fonts/maledpan-bold.woff2) format("woff2"), url(./fonts/maledpan-bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: seed-icons;
    src: local("seed-icons"), url(fonts/seed-icons/seed-icons.woff2?seed) format("woff2"), url(./fonts/seed-icons/seed-icons.woff?seed) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: fallback
}

h1, h2, h3, h4, h5 {
  font-family: "Maledpan",sans-serif;
}


p {
  font-size: 18px;
  line-height: 25px;
  font-family: sans-serif;
}

b {
  font-weight: 700;
}

header {
  overflow: hidden;
  text-align: center;
}

.header-description, .video-player {
  font-family: "Maledpan",sans-serif;
  font-size:25px;
  line-height: 33px;
}


.video-background {
  text-align: center;
  color: #fff;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

video {
  min-height: 100%;
  width:100%;
  z-index: -999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}


.video-embed {
  background-color: #1a2956;
}

header .video-embed iframe {
  text-align: center;
  min-height: 400px;
  display: inline-block;
  max-width: 800px;
}




.video-background .header-title {
  z-index: 999!important;
  margin-top: 200px;
  font-size: 50px;
  font-weight: 400;
}

.video-background .header-description, .creator-list {
  padding: 60px 15%;
}

.creator-about {
  padding: 0 25%;
}

.influencer-category {
  margin: 40px 0;
  text-transform: uppercase;
  color: #21417d;
  letter-spacing: 4px;
  font-weight: 400;
  padding: 0 0 0 20px;
  border-left: solid #21417d 5px;
}

.creator {
  width: 23%;
  display: inline-block;
  min-height: 400px;
  padding: 5px;
  vertical-align: top;
}

.creator-about-close-button {
  position: fixed;
  top:30px;
  right: 30px;
  font-size:20px;
  z-index: 999;
}

.creator-about-close-button:hover {
  opacity: 0.8;
}

.creator-image:hover {
  transform: scale(1.01);
  transition: ease 0.1s;
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.04),-10px 0 20px 0px rgba(0,0,0,0.04);
}

.creator-image {
  width: 100%;
  height:220px;
  transition: ease 0.1s;
  background-color: #fafafa;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}


.creator-info h1, .creator-info h2 {
  margin: 5px 0;
}

.creator-info h1 {
  line-height: 40px;
  font-size:27px;
}

.creator-about h1 {
  font-size:40px;
  line-height: 30px;
}

.creator-about h2 {
  font-size:27px;
  color:#1c3d7a;
}

.creator-info h2 {
  font-size:18px;
  color:#1c3d7a;
  font-weight: 300;
}

.creator-about  {
  margin-top: 100px;
}

.creator-about .creator-image {
  width: 300px;
  height: 300px;
  display:inline-block;
}


.creator-about .creator-hero {
  padding-left: 40px;
  display:inline-block;
}

.creator-about p {
  margin-top: 70px;
}

.creator-social {
  margin-top: 30px;
  font-size: 20px;
}

.creator-social i {
  margin-right: 8px;
}

.creator-social i:hover {
  color:#1c3d7a;
}

footer p {
  padding-top: 40px;
  font-size: 14px;
  text-align: center;
}

video {
  object-fit: cover;
}



@media only screen and (max-width: 960px) {

  .video-background .header-description, .creator-list {
    padding: 0px 5%;
  }

  .creator-about .creator-hero {
    display:block;
  }

  .creator-info {
    padding: 10px 5%;
  }

  .creator-about {
    padding: 0 3%;
  }

  .creator-about .creator-image {
      width: 100%;
      background-size:contain;
      min-height: 500px;
    }

  .creator-about .creator-hero {
    display: block;
    margin-top: 20px;
    padding-left: 0px;
  }

  .creator {
    width: 45%;
  }



}

[class*=" si-"],
[class^=si-] {
    font-family: seed-icons!important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.06em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.si-home:before {
    content: "\e900"
}

.si-home-alt:before {
    content: "\e901"
}

.si-menu:before {
    content: "\e902"
}

.si-paper-clip:before {
    content: "\e903"
}

.si-image:before {
    content: "\e904"
}

.si-camera:before {
    content: "\e905"
}

.si-video:before {
    content: "\e906"
}

.si-paper:before {
    content: "\e907"
}

.si-shuffle:before {
    content: "\e908"
}

.si-repeat:before {
    content: "\e909"
}

.si-briefcase:before {
    content: "\e90a"
}

.si-box:before {
    content: "\e90b"
}

.si-share:before {
    content: "\e90c"
}

.si-trash:before {
    content: "\e90d"
}

.si-pencil-o:before {
    content: "\e90e"
}

.si-pencil:before {
    content: "\e90f"
}

.si-play:before {
    content: "\e910"
}

.si-play-o:before {
    content: "\e911"
}

.si-zoom-in:before {
    content: "\e912"
}

.si-zoom-out:before {
    content: "\e913"
}

.si-search-o:before {
    content: "\e914"
}

.si-search:before {
    content: "\e915"
}

.si-basket:before {
    content: "\e916"
}

.si-basket-alt:before {
    content: "\e917"
}

.si-cart:before {
    content: "\e918"
}

.si-cart-alt:before {
    content: "\e919"
}

.si-cart-o:before {
    content: "\e91a"
}

.si-bag-alt:before {
    content: "\e94a"
}

.si-bag:before {
    content: "\e91b"
}

.si-globe:before {
    content: "\e91c"
}

.si-location:before {
    content: "\e91d"
}

.si-mail:before {
    content: "\e91e"
}

.si-phone:before {
    content: "\e91f"
}

.si-phone-o:before {
    content: "\e920"
}

.si-phone-alt:before {
    content: "\e921"
}

.si-mobile:before {
    content: "\e922"
}

.si-fax:before {
    content: "\e923"
}

.si-fax-o:before {
    content: "\e924"
}

.si-calendar:before {
    content: "\e960"
}

.si-calendar-alt:before {
    content: "\e925"
}

.si-clock:before {
    content: "\e926"
}

.si-user:before {
    content: "\e927"
}

.si-folder:before {
    content: "\e928"
}

.si-tag:before {
    content: "\e929"
}

.si-comment:before {
    content: "\e92a"
}

.si-upload:before {
    content: "\e92b"
}

.si-download:before {
    content: "\e92c"
}

.si-plus:before {
    content: "\e92d"
}

.si-minus:before {
    content: "\e92e"
}

.si-arrow-left:before {
    content: "\e92f"
}

.si-arrow-right:before {
    content: "\e930"
}

.si-arrow-up:before {
    content: "\e931"
}

.si-arrow-down:before {
    content: "\e932"
}

.si-check-o:before {
    content: "\e933"
}

.si-cross-o:before {
    content: "\e934"
}

.si-check:before {
    content: "\e95b"
}

.si-cross:before {
    content: "\e95c"
}

.si-dashboard:before {
    content: "\e95d"
}

.si-ellipsis:before {
    content: "\e935"
}

.si-chevron-left:before {
    content: "\e936"
}

.si-chevron-right:before {
    content: "\e937"
}

.si-chevron-down:before {
    content: "\e938"
}

.si-chevron-up:before {
    content: "\e939"
}

.si-caret-left:before {
    content: "\e93a"
}

.si-caret-right:before {
    content: "\e93b"
}

.si-caret-down:before {
    content: "\e93c"
}

.si-caret-up:before {
    content: "\e93d"
}

.si-angle-left:before {
    content: "\e93e"
}

.si-angle-right:before {
    content: "\e93f"
}

.si-angle-up:before {
    content: "\e940"
}

.si-angle-down:before {
    content: "\e941"
}

.si-eye:before {
    content: "\e942"
}

.si-qrcode:before {
    content: "\e943"
}

.si-barcode:before {
    content: "\e944"
}

.si-ampersand:before {
    content: "\e945"
}

.si-cog:before {
    content: "\e946"
}

.si-book:before {
    content: "\e947"
}

.si-info:before {
    content: "\e948"
}

.si-info-o:before {
    content: "\e949"
}

.si-file:before {
    content: "\e95e"
}

.si-file-pdf:before {
    content: "\e94b"
}

.si-facebook:before {
    content: "\e94c"
}

.si-facebook-circled:before {
    content: "\e94d"
}

.si-facebook-squared:before {
    content: "\e94e"
}

.si-twitter:before {
    content: "\e94f"
}

.si-twitter-circled:before {
    content: "\e950"
}

.si-line:before {
    content: "\e951"
}

.si-line-circle:before {
    content: "\e952"
}

.si-line-square:before {
    content: "\e953"
}

.si-vimeo:before {
    content: "\e954"
}

.si-vimeo-circle:before {
    content: "\e955"
}

.si-instagram:before {
    content: "\e956"
}

.si-instagram-circled:before {
    content: "\e961"
}

.si-youtube:before {
    content: "\e957"
}

.si-youtube-circle:before {
    content: "\e958"
}

.si-pinterest:before {
    content: "\e959"
}

.si-pinterest-circled:before {
    content: "\e962"
}

.si-google-logo:before {
    content: "\e95a"
}

.si-facebook-logo:before {
    content: "\e95f"
}
